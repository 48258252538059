import PropagateLoader from "react-spinners/PropagateLoader";
import logo from "../../assets/Metrk-logo.webp"
import { HomeAboutTextData } from "../../modules/home/data/mock/home.ts";
import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: "#072120",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <img
        src={logo}
        alt="logo"
        style={{ width: "15rem", height: "15rem", marginBottom: "3rem" }}
      />
      <h1
        style={{
          color: "white",
          marginBottom: "1rem",
          fontFamily: "Montserrat",
        }}
      >
        {HomeAboutTextData.MetrkSlogan}
      </h1>
      <PropagateLoader color={"#ce974b"} loading={true} size={10} />
    </div>
  );
};

export default Loading;
