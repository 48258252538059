import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import 'bootstrap/dist/css/bootstrap.css';
import './core/constants/fonts.css'
import './core/theme/theme.css'
import './core/constants/spaces.css'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({

  typography: {

    fontFamily: "'Montserrat', sans-serif"
  },

})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

