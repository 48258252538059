// static text data
export class NavTextData {
  static readonly logoTitle = "METRK";
  static readonly logoTitleWithoutM = "ETRK";
  static readonly Aboutus = "About";
  static readonly Contactus = "Contact";
  static readonly Services = "Services";
  static readonly Career = "Careers";
  static readonly PrivacyPolice = "Policy";
}

export class HomeAboutTextData {
  static readonly About = "About";
  static readonly MetrkSlogan = "MAKE YOUR MARK";
  static readonly AboutContent =
    "METRK is a dynamic and innovative limited liability company established by the Egyptian investment law (GAFI) in 2023. We specialize in providing our clients with full marketing and integrated business development solutions. Our highly experienced experts from diverse industries are committed to delivering exceptional results through customized solutions that exceed our client's expectations.";
}

export class servicesCarouselTextData {
  static readonly title1 = "Advertising Services";
  static readonly title2 = "Bussiness Development";
  static readonly title3 = "Website Development";
  static readonly title4 = "Digital Marketing";
  static readonly title5 = "Financial Services";
  static readonly title6 = "Marketing Research and Analytics";
  static readonly title7 = "Media Production Services";
  static readonly title8 = "Photography";
  static readonly title9 = "Printings";
  static readonly title10 = "Videography";
  static readonly title11 = "Events Planning";

  static readonly content1 =
    "Strategy Development, Creative Services, Media Planning and Buying, Branding Services, Analytics and Reporting, Outdoor Advertisement";
  static readonly content2 =
    "Setting goals and developing, Plans for renewed growth, Manage leads in the sales cycle, Implement new target market initiatives";
  static readonly content3 =
    "Platform strategy and planning, Platform architecture design, Platform development, Platform customization, Platform maintenance and support";
  static readonly content4 =
    "Search Engine Optimization (SEO), Content Marketing, Pay-Per-Click (PPC) advertising, Email Marketing, Market Research, Video Production, Public Relations (PR), Social Media Marketing (SMM)";
  static readonly content5 =
    "Establishing companies, Bookkeeping, Financial planning, investment advice, risk management, tax planning, and related services, Consultants work closely with clients to achieve financial goals and objectives";
  static readonly content6 =
    "Study consumer preferences and business conditions, Gather and analyze competitors and their positioning, Consumer data analysis, Pricing and offers, Market potentials";
  static readonly content7 =
    "Pre-production services, Production services, Post-production services, Animation Services, Graphic design services, Distribution Services";
  static readonly content8 =
    "Social Media Photography, Product Photography, Corporate Portraits, Marketing Events Photography, Product Launch Photography";
  static readonly content9 =
    "Brochures, Business Crads, Catalogs, Letterheads and Banners, Rollups, Thank you Cards, Giveaways";
  static readonly content10 =
    "Brand Documentary, Brand Awareness, Event Videography, Drone Videography, Product Videography";
  static readonly content11 =
    "Networking Events, Workshops, Team Building, Social Events, Product launch event, Internal Corporate Event";
}

export class FooterTextData {
  static readonly logoTitle = "METRK";
  static readonly logoTitleWithoutM = "ETRK";
  static readonly MetrkGoals =
    "strives to be the go-to provider of modern, innovative business solutions that exceed clients' expectations. We aim to enhance customer satisfaction by delivering high-quality services and solutions that meet unique needs and goals in an efficient time.";
  static readonly MetrkPhone = "+20 100 055 8113";
  static readonly MetrkEmail = "info@metrk.com.eg";
  static readonly MetrkWebsite = "www.metrk.com.eg";
  static readonly MetrkAddres =
    "Building 198, S Teseen, Al Andalus, 5th settlement, New Cairo, Cairo, Egypt";
  static readonly MetrkLocation = "https://maps.app.goo.gl/dZHw3pe9VpE4cve89"
  static readonly Contact = "Contact";
  static readonly Career = "Careers";
  static readonly Services = "Services";
  static readonly Service1 = "Marketing Research And Analytics";
  static readonly Service2 = "Digital Marketing";
  static readonly Service3 = "Business Development";
  static readonly Service4 = "Media Production Services";
  static readonly Service5 = "Website Development";
  static readonly Service6 = "Events Planning";
  static readonly SocialNetworkTitle =
    "Get connected with us on social networks:";
}
