import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AppRoutes } from "./core/routes/AppRoutes.tsx";
import { useEffect, lazy, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


import Loading from "./core/components/Loading.tsx";
import React from "react";
const Home = lazy(() => import("./modules/home/presenation/Home.tsx"))
const Policy = lazy(() => import("./modules/policy/presentation/Policy.tsx"))
const AboutUs = lazy(() => import("./modules/aboutUs/presentation/AboutUs.tsx"))
const ContactUs = lazy(() => import("./modules/contactUs/presentation/ContactUs.tsx"))
const ServicesComponent = lazy(() => import("./modules/services-page/presentation/ServicesComponent.tsx"))
const WebPotfolio = lazy(() => import("./modules/web-portfolio/presentation/webpotfolio.tsx"))

const App = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);



  return (
    <>
      <Suspense fallback={<Loading />}>
        <Router>
          <Routes>
            <Route path={AppRoutes.home} element={<Home />} />
            <Route path={AppRoutes.contactusParam} element={<ContactUs />} />
            <Route
              path={AppRoutes.contactus}
              element={<Navigate replace to={AppRoutes.contactus + "/0"} />}
            />
            <Route path={AppRoutes.aboutus} element={<AboutUs />} />
            <Route path={AppRoutes.policy} element={<Policy />} />
            <Route path={AppRoutes.services} element={<ServicesComponent />} />
            <Route path={AppRoutes.webPortflio} element={<WebPotfolio />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
